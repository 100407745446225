// Generated by Framer (241a4b4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Mailchimp from "https://framerusercontent.com/modules/0ZSF8VJcL5AhXebKiUw3/nbsZGufHZBGrLw7CNirb/Mailchimp.js";
const MailchimpFonts = getFonts(Mailchimp);

const cycleOrder = ["So5prwQUT", "WVdP2FYiN", "CUEe9DwKO"];

const variantClassNames = {CUEe9DwKO: "framer-v-1xlcwk9", So5prwQUT: "framer-v-vjz0yf", WVdP2FYiN: "framer-v-ad4yrc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "So5prwQUT", Phone: "WVdP2FYiN", Tablet: "CUEe9DwKO"}

const getProps = ({height, id, opacity, showHide, width, ...props}) => { return {...props, f998L4dna: showHide ?? props.f998L4dna ?? true, FqdqxCHEI: opacity ?? props.FqdqxCHEI ?? 1, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "So5prwQUT"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;showHide?: boolean;opacity?: number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, f998L4dna, FqdqxCHEI, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "So5prwQUT", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-c9jhK", classNames)} style={{display: "contents"}}>
{f998L4dna && (<Transition value={transition}><motion.div {...restProps} className={cx("framer-vjz0yf", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"So5prwQUT"} ref={ref} style={{opacity: FqdqxCHEI, ...style}} {...addPropertyOverrides({CUEe9DwKO: {"data-framer-name": "Tablet"}, WVdP2FYiN: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1dkpoyw-container"} layoutDependency={layoutDependency} layoutId={"xV04iFTh1-container"}><Mailchimp borderRadius={8} bottomLeftRadius={0} bottomRightRadius={8} button={{color: "rgb(255, 255, 255)", fill: "var(--token-52513685-d42d-4a43-abe2-b9f5646713f0, rgb(254, 118, 36)) /* {\"name\":\"Theme Colors/Primary\"} */", fontWeight: 600, label: "        SUBSCRIBE        "}} font fontFamily={"switzer"} fontSize={18} fontWeight={400} gap={0} height={"100%"} id={"xV04iFTh1"} input={{color: "rgb(29, 29, 29)", error: "rgb(238, 68, 68)", fill: "var(--token-25011c2b-ba34-47f0-8040-2687ea1f377a, rgb(255, 255, 255)) /* {\"name\":\"Accent/White\"} */", placeholder: "Enter email address", placeholderColor: "var(--token-ec5598cc-a704-42f2-93c4-534e256df791, rgb(75, 107, 123)) /* {\"name\":\"Theme Colors/Neutral/06\"} */", value: ""}} isMixedBorderRadius layout={"horizontal"} layoutId={"xV04iFTh1"} padding={22} paddingBottom={22} paddingLeft={28} paddingPerSide paddingRight={28} paddingTop={22} style={{width: "100%"}} topLeftRadius={0} topRightRadius={8} url={""} width={"100%"} {...addPropertyOverrides({WVdP2FYiN: {gap: 16, isMixedBorderRadius: false, layout: "vertical"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>)}
</motion.div>
</LayoutGroup>)

});

const css = [".framer-c9jhK [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-c9jhK .framer-1ul24nv { display: block; }", ".framer-c9jhK .framer-vjz0yf { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 604px; }", ".framer-c9jhK .framer-1dkpoyw-container { flex: none; height: auto; position: relative; width: 604px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-c9jhK .framer-vjz0yf { gap: 0px; } .framer-c9jhK .framer-vjz0yf > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-c9jhK .framer-vjz0yf > :first-child { margin-left: 0px; } .framer-c9jhK .framer-vjz0yf > :last-child { margin-right: 0px; } }", ".framer-c9jhK.framer-v-ad4yrc .framer-vjz0yf { padding: 0px 25px 0px 25px; width: 390px; }", ".framer-c9jhK.framer-v-ad4yrc .framer-1dkpoyw-container { flex: 1 0 0px; width: 1px; }", ".framer-c9jhK.framer-v-1xlcwk9 .framer-vjz0yf { width: 810px; }", ".framer-c9jhK.framer-v-1xlcwk9 .framer-1dkpoyw-container { width: 560px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 69
 * @framerIntrinsicWidth 604
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"WVdP2FYiN":{"layout":["fixed","auto"]},"CUEe9DwKO":{"layout":["fixed","auto"]}}}
 * @framerVariables {"f998L4dna":"showHide","FqdqxCHEI":"opacity"}
 */
const FramerPe_w00e7f: React.ComponentType<Props> = withCSS(Component, css, "framer-c9jhK") as typeof Component;
export default FramerPe_w00e7f;

FramerPe_w00e7f.displayName = "Reponsive-form";

FramerPe_w00e7f.defaultProps = {height: 69, width: 604};

addPropertyControls(FramerPe_w00e7f, {variant: {options: ["So5prwQUT", "WVdP2FYiN", "CUEe9DwKO"], optionTitles: ["Variant 1", "Phone", "Tablet"], title: "Variant", type: ControlType.Enum}, f998L4dna: {defaultValue: true, title: "Show|Hide", type: ControlType.Boolean}, FqdqxCHEI: {defaultValue: 1, displayStepper: false, max: 1, min: 0, step: 0.01, title: "Opacity", type: ControlType.Number}} as any)

addFonts(FramerPe_w00e7f, [...MailchimpFonts])